<template>
  <div class="appScenarios">
    <div class="banner">
      <div class="w-auto">
        <h2 class="title">应用场景</h2>
        <p class="content">
          基于区块链可信网络创新创业平台，遵循信创产品兼容性能力，已在众多应用场景落地。
        </p>
      </div>
    </div>
    <!-- 大屏幕 -->
    <ul class="swiper-contain maxScreen">
      <li class="swiper-item" v-for="(item, index) in items" :key="item.id">
        <div class="imgBox">
          <img
            class="swiper-logo"
            :src="require('../../assets/img/appScenarios/' + item.imgUrl)"
            alt=""
          />
        </div>
        <div class="swiper-content">
          <h4 class="content-title">{{ item.title }}</h4>
          <p class="content-description">
            {{ item.content }}
          </p>
        </div>
      </li>
    </ul>

    <!-- 小屏幕 -->
    <ul class="swiper-contain minScreen">
      <li
        class="swiper-item"
        v-for="(item, index) in items"
        :key="item.id"
        v-if="index < 3 * batch"
      >
        <div class="imgBox">
          <img
            class="swiper-logo"
            :src="require('../../assets/img/appScenarios/' + item.imgUrl)"
            alt=""
          />
        </div>
        <div class="swiper-content">
          <h4 class="content-title">{{ item.title }}</h4>
          <p class="content-description">
            {{ item.content }}
          </p>
        </div>
      </li>
    </ul>
    <div class="swiper-bottom minScreen">
      <button class="loadingMore" @click="loadingMore">
        {{ noMore ? "没有更多了" : "---加载更多---" }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { items } from "./swiperItem";
export default {
  data() {
    return {
      items,
      batch: 1,
      noMore: false,
      element: {},
    };
  },
  mounted() {
    this.element = document.documentElement;
    this.watchScroll();
  },
  destroyed() {
    window.removeEventListener("scroll", this.watchScroll, false);
  },
  methods: {
    // 监听屏幕滚动
    watchScroll() {
      let resizeWaiter = false;
      window.addEventListener("scroll", (res) => {
        if (!resizeWaiter) {
          resizeWaiter = true;
          setTimeout(() => {
            console.log(res, "滚动回调");
            let scrollTop = this.element.scrollTop || document.body.scrollTop;
            let clientHeight = this.element.clientHeight;
            let scrollHeight = this.element.scrollHeight;
            console.log(scrollTop, clientHeight, scrollHeight, "数据");
            // 当距离触底300px时触发加载
            if (scrollTop + clientHeight >= scrollHeight - 300) {
              console.log("触发");
              this.loadingMore();
            }
            resizeWaiter = false;
          }, 300);
        }
      });
    },
    // 触底判断
    touchGround() {},
    // 加载更多
    loadingMore() {
      if (this.noMore || this.batch == 7) return;
      if (this.batch >= 6) {
        this.noMore = true;
        window.removeEventListener("scroll", false);
        return;
      }
      this.batch += 1;
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and(min-width:520px) {
  .appScenarios {
    .banner {
      height: 360px;
      background-image: url("../../assets/img/appScenarios/应用场景_banner_max.png");
      background-repeat: no-repeat;
      background-position: center;
      padding: 20px;
      .title {
        color: #fff;
        margin-top: 70px;
        margin-bottom: 15px;
        font-size: 42px;
        font-weight: 400;
      }
      .content {
        color: #fff;
        font-size: 16px;
      }
    }
    .swiper-contain {
      padding: 50px 0 60px;
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      background-color: #f7f8fa;
      // margin-top: 0.9375rem;
      .swiper-item {
        width: 386px;
        background-color: #fff;
        border-radius: 2px;
        margin: 20px;
        overflow: hidden;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
        .imgBox {
          width: 386px;
          height: 160px;
          overflow: hidden;
        }
        .swiper-logo {
          transition: all 0.5s;
          &:hover {
            transform: scale(1.2);
          }
        }
        .swiper-content {
          height: 150px;
          padding: 15px;
          // padding: 0.625rem;
          .content-title {
            font-weight: 400;
            margin-bottom: 10px;
            font-size: 18px;
            color: #3e3e3e;
            &:hover {
              color: #387efd;
            }
          }
          .content-description {
            color: #777a8c;
            font-size: 14px;
          }
        }
      }
    }
    .minScreen {
      display: none;
    }
  }
}

@media screen and(min-width:1700px) {
  .appScenarios {
    .swiper-contain {
      padding: 50px 150px 60px;
    }
  }
}

@media screen and(max-width:520px) {
  .appScenarios {
    .banner {
      height: 245px;
      background-image: url("../../assets/img/appScenarios/banner.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 20px;
      .title {
        color: #fff;
        font-weight: 400;
        margin: 30px 0 30px;
      }
      .content {
        color: #fff;
      }
    }
    .maxScreen {
      display: none;
    }
    .minScreen {
      display: block;
    }
    .swiper-contain {
      margin-top: 0.9375rem;
      .swiper-item {
        margin: 0 auto;
        width: 90%;
        height: 288px;
        margin-bottom: 20px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
        .imgBox {
          width: 100%;
          overflow: hidden;
        }
        .swiper-logo {
          width: 100%;
          height: 43%;
          object-fit: cover;
          transition: all 0.5s;
          &:hover {
            transform: scale(1.1);
          }
        }
        .swiper-content {
          padding: 0.625rem;
          .content-title {
            font-weight: 400;
            margin-bottom: 0.625rem;
            font-size: 18px;
            font-weight: 500;
            color: #3e3e3e;
            &:hover {
              color: #387efd;
            }
          }
          .content-description {
            color: #777a8c;
            font-size: 14px;
          }
        }
      }
    }
    .swiper-bottom {
      height: 30px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      .loadingMore {
        color: #777a8c;
        // margin: 0 auto;
      }
    }
  }
}
@media screen and(min-width:768px) {
}
</style>