export const items = [{
    id: 1,
    title: '区块链+农业',
    imgUrl: '区块链+农业.png',
    content: '在生产线中对每件商品的赋码，消费者扫码可查询产品真伪信息、原粮信息、生产加工信息和物流信息，让消费者感知产品从种子到餐桌的全过程。',
  },
  {
    id: 2,
    title: '区块链+贸易金融',
    imgUrl: '区块链+贸易金融.png',
    content: '以区块链技术为基础，联合跨境贸易平台、国内外银行、供应链服务商等建立数字化国际贸易和金融服务平台。',
  },
  {
    id: 3,
    title: '区块链+风控管理',
    imgUrl: '区块链+风控管理.png',
    content: '以区块链技术为基础，联合跨境贸易平台、国内外银行、供应链服务商等建立数字化国际贸易和金融服务平台。',
  },
  {
    id: 4,
    title: '区块链+跨境电商',
    imgUrl: '区块链+跨境电商.png',
    content: '以区块链技术为基础，联合跨境贸易平台、国内外银行、供应链服务商等建立数字化国际贸易和金融服务平台。',
  },
  {
    id: 5,
    title: '区块链+跨境金融',
    imgUrl: '区块链+跨境金融.png',
    content: '在跨境金融区块链平台上开展新通道物流融资结算、出口应收账款融资等融资类场景的应用和服务贸易税务备案电子化银行核验等支付便利化类场景的业务应用。',
  },
  {
    id: 6,
    title: '区块链+股权市场',
    imgUrl: '区块链+股权市场.png',
    content: '基于区块链技术的业务服务平台，链上完成登记结算、交易报告等业务，完成与证监会监管链的对接联通，提高传统业务的运作效率。',
  },
  {
    id: 7,
    title: '区块链+人工智能',
    imgUrl: '区块链+人工智能.png',
    content: '基于区块链的人工智能网络可以设定一致、有效的设备注册、授权及完善的生命周期管理机制，实现契约管理，防止设备被滥用、用户受到伤害，为用户提供更好的服务。',
  },
  {
    id: 8,
    title: '区块链+NFT（数字资产）',
    imgUrl: '区块链+NFT（数字资产）.png',
    content: 'NFT在区块链技术支持下，对数字艺术、门票等任何种类的数字商品进行确权。一件数字商品通过NFT手段加密后，生成一种不可替代的数字资产。',
  },
  {
    id: 9,
    title: '区块链+版权',
    imgUrl: '区块链+版权.png',
    content: '运用区块链技术固定权属信息，完成版权认证、登记、转让等流程操作，加快溯源取证流程，降低版权质押融资认证难度。',
  },
  {
    id: 10,
    title: '区块链+教育',
    imgUrl: '区块链+教育.png',
    content: '支持教育用户身份认证、数字教育档案存证与追踪、敏感信息流通控制与隐私保护、基于学分银行的终身学习等创新技术研发与应用。',
  },
  {
    id: 11,
    title: '区块链+制造',
    imgUrl: '区块链+制造.png',
    content: '基于区块链的供应链管理平台，将供应链物流、交易、资金等信息融合，提升供应链管理效率，降低行业经营风险和成本。',
  },
  {
    id: 12,
    title: '区块链+能源',
    imgUrl: '区块链+能源.png',
    content: '利用区块链促进源网荷储各环节用户侧资源可信采集、处理与交互，构建多方可信的交易环境与规则体系，解决能源互联网规模场景庞大、互联链条复杂、信任传导差等难题。',
  },
  {
    id: 13,
    title: '区块链+人社',
    imgUrl: '区块链+人社.png',
    content: '支持开展社保参保证明、电子劳动合同等区块链应用，支持与其他政府部门行业链、城市区块链、社会机构链的互通互信。',
  },
  {
    id: 14,
    title: '区块链+卫生健康',
    imgUrl: '区块链+卫生健康.png',
    content: '在数据共享方面，运用区块链对数据进行存证，确保数据完整性和可追溯性；在健康档案共享查阅方面，推动医疗机构数据上链，为居民提供优质健康服务。',
  },
  {
    id: 15,
    title: '区块链+民政',
    imgUrl: '区块链+民政.png',
    content: '电子证照跨链互认方面，推动婚姻登记证、社会组织法人登记证等上链并实现跨区域可信共享。',
  },
  {
    id: 16,
    title: '区块链+政务服务',
    imgUrl: '区块链+政务服务.png',
    content: '基于区块链技术的电子证照服务平台，推动电子证照跨域互认互信，进一步推动减材料、免证办。',
  },
  {
    id: 17,
    title: '区块链+政务数据共享',
    imgUrl: '区块链+政务数据共享.png',
    content: '提升政务数据流转共享和全流程安全可控能力，营造区块链+政务数据共享良好生态，不断加强政务数据安全有序共享。',
  },
  {
    id: 18,
    title: '区块链+法治',
    imgUrl: '区块链+法治.png',
    content: '利用区块链技术辅助实现行政执法三项制度的落实对行政执法主体、行政执法人员资格、行政执法全过程进行记录存证和合法性审核，进一步加强行政执法监督质效。',
  },
  {
    id: 19,
    title: '区块链+税费服务',
    imgUrl: '区块链+税费服务.png',
    content: '利用区块链数据防篡改、流程可追溯的技术特点，构建涉税涉费信息共识共享平台，建立征缴更高效、服务更便捷的部门协作管理机制。',
  },
  {
    id: 20,
    title: '区块链+审判',
    imgUrl: '区块链+审判.png',
    content: '通过运用司法区块链技术，实现异构数据上链存证验证，帮助当事人降低成本、高效固定和追溯有关电子证据等数据，减轻人民群众诉累。',
  },
  {
    id: 21,
    title: '区块链+检察',
    imgUrl: '区块链+检察.png',
    content: '利用区块链技术开展数字化、线上化等技术存证固证应用并辅助电子数据审查，提升办案质效。',
  },
]